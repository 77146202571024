// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-en-privacy-policy-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/en/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-policy-tsx" */),
  "component---src-pages-en-terms-of-use-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/en/terms_of_use.tsx" /* webpackChunkName: "component---src-pages-en-terms-of-use-tsx" */),
  "component---src-pages-index-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ru-privacy-policy-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/ru/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-ru-privacy-policy-tsx" */),
  "component---src-pages-ru-terms-of-use-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/ru/terms_of_use.tsx" /* webpackChunkName: "component---src-pages-ru-terms-of-use-tsx" */),
  "component---src-pages-verified-tsx": () => import("/Volumes/Projects/swapp/packages/landing/src/pages/verified.tsx" /* webpackChunkName: "component---src-pages-verified-tsx" */)
}

exports.data = () => import("/Volumes/Projects/swapp/packages/landing/.cache/data.json")

